import CountriesList from "../constants/countries";
import httpService from "../services/HttpService";
import { reportErrorToNewRelic } from "../helpers/newRelic";

let idTypesList = [];
httpService
  .get(`${process.env.REACT_APP_API2_EP}${process.env.REACT_APP_IDTYPES_LIST}`)
  .then(res => {
    console.log("Select Values Function Response = ", res);
    idTypesList = res.data.list;
  })
  .catch(err => {
    reportErrorToNewRelic("IDTYPES_LIST", err);
  });

const demoInputs = [
  {
    type: "text",
    validation_type: false,
    autocomplete: "given-name",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.paxFirstName",
    default_name: "Nombre",
    placeholder_id: "paxForm.paxFirstNamePh",
    default_placeholder: "Ej: Juan Carlos",
    required: true,
    field_name: "firstname",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "family-name",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.paxLastName",
    default_name: "Apellido",
    placeholder_id: "paxForm.paxLastNamePh",
    default_placeholder: "Ej: Pérez",
    required: true,
    field_name: "lastname",
    hidden: false,
    default_value: null
  },
  {
    type: "country",
    autocomplete: "country-name",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.paxNationality",
    default_name: "Nacionalidad",
    placeholder_id: "paxForm.paxNationalityPh",
    default_placeholder: "Seleccione su país de nacimiento",
    required: true,
    field_name: "nationality",
    hidden: false,
    default_value: null
  },
  {
    type: "country",
    autocomplete: "country-name",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxCountry",
    default_name: "País de residencia",
    placeholder_id: "paxForm.paxCountryPh",
    default_placeholder: "Seleccione su país de residencia",
    required: true,
    field_name: "country",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: "email",
    autocomplete: "email",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxEmail",
    default_name: "Email",
    placeholder_id: "paxForm.paxEmailPh",
    default_placeholder: "Ej: juancarlos@gmail.com",
    required: true,
    field_name: "email",
    hidden: false,
    default_value: null
  },
  {
    type: "select",
    autocomplete: "",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.idType",
    default_name: "Tipo de Documento",
    placeholder_id: "paxForm.idType",
    default_placeholder: "Seleccione un tipo de documento",
    required: true,
    field_name: "idtype",
    hidden: false,
    default_value: null,
    select_values: function (paxData) {
      if (typeof paxData === "object") {
        let countryCode = CountriesList.find(
          country => country.Value === paxData.nationality
        );
        if (countryCode) countryCode = countryCode.Code;
        console.log("countryCodeFromList", countryCode, paxData.nationality);
        if (!countryCode) countryCode = null;
        console.log("finalCountryCode", countryCode);
        if (countryCode) countryCode = countryCode.toLowerCase();
        let idTypes = idTypesList.filter(
          val => val.country_code === countryCode || !val.country_code
        );
        return idTypes;
      }
    }
  },
  {
    type: "text",
    validation_type: "alphanumeric",
    autocomplete: "",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.paxIdNumber",
    default_name: "Documento / Pasaporte",
    placeholder_id: "paxForm.paxIdNumberPh",
    default_placeholder: "Ej: 24578976 / AA121247",
    required: true,
    field_name: "idnumber",
    hidden: false,
    default_value: null
  },
  {
    type: "date",
    validation_type: false,
    autocomplete: "bday",
    review_only: false,
    pax_type: "all",
    name_id: "paxForm.paxBirthdate",
    default_name: "Fecha de Nacimiento",
    placeholder_id: "paxForm.paxBirthdatePh",
    default_placeholder: "Ingrese su fecha de nacimiento",
    required: true,
    field_name: "birthdate",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "street-address",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxAddress",
    default_name: "Dirección",
    placeholder_id: "paxForm.paxAddressPh",
    default_placeholder: "Ej: Avenida San Martín 123, Piso 1, Depto 2",
    required: true,
    field_name: "address",
    hidden: false,
    default_value: null
  },

  {
    type: "text",
    validation_type: false,
    autocomplete: "address-level1",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxState",
    default_name: "Provincia / Estado",
    placeholder_id: "paxForm.paxStatePh",
    default_placeholder: "Ej: Mendoza",
    required: true,
    field_name: "state",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "address-level2",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxCity",
    default_name: "Ciudad",
    placeholder_id: "paxForm.paxCityPh",
    default_placeholder: "Ej: Ciudad de Mendoza",
    required: true,
    field_name: "city",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "zipcode",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.zipCode",
    default_name: "Código Postal",
    placeholder_id: "paxForm.zipCode",
    default_placeholder: "Ej: M5500",
    required: false,
    field_name: "zipcode",
    hidden: false,
    default_value: null
  },
  {
    type: "phone",
    autocomplete: "tel",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxPhones",
    default_name: "Teléfono",
    placeholder_id: "paxForm.paxPhonesPh",
    default_placeholder: "Ingrese su teléfono de contacto",
    required: true,
    field_name: "phones",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "organization-title",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxProfession",
    default_name: "Profesión",
    placeholder_id: "paxForm.paxProfessionPh",
    default_placeholder: "Ej: Comerciante",
    required: true,
    field_name: "profession",
    hidden: false,
    default_value: null
  },
  {
    type: "select",
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxMaritalStatus",
    default_name: "Estado Civil",
    placeholder_id: "paxForm.paxMaritalStatusPh",
    default_placeholder: "Seleccione su estado civil",
    required: true,
    field_name: "maritalstatus",
    hidden: false,
    default_value: null,
    select_values: [
      {
        label: "Soltero",
        label_id: "paxForm.civilStatus.single",
        value: "Soltero"
      },
      {
        label: "Casado",
        label_id: "paxForm.civilStatus.married",
        value: "Casado"
      },
      {
        label: "Divorciado",
        label_id: "paxForm.civilStatus.divorced",
        value: "Divorciado"
      },
      {
        label: "Viudo",
        label_id: "paxForm.civilStatus.widower",
        value: "Viudo"
      }
    ]
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxCar",
    default_name: "Vehículo (opcional)",
    placeholder_id: "paxForm.paxCarPh",
    default_placeholder: "Ej: Ford Fiesta Mod. 2014",
    required: false,
    field_name: "car",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxPatent",
    default_name: "Patente del Vehículo (opcional)",
    placeholder_id: "paxForm.paxPatentPh",
    default_placeholder: "Ej: ABC 123 EFG",
    required: false,
    field_name: "patent",
    hidden: false,
    default_value: null
  },
  {
    type: "time",
    validation_type: false,
    autocomplete: false,
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.arrivalHour",
    default_name: "Hora de llegada al hotel",
    placeholder_id: "paxForm.arrivalHourPH",
    default_placeholder: "Hora de llegada al hotel",
    required: true,
    field_name: "arrivaltime",
    hidden: false,
    default_value: null
  },
  {
    type: "text",
    validation_type: false,
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxFlight",
    default_name: "Detalles del vuelo",
    placeholder_id: "paxForm.paxFlightPh",
    default_placeholder: "Ej: Número de vuelo y fecha de aterrizaje",
    required: false,
    field_name: "flightdetail",
    hidden: false,
    default_value: null
  },
  {
    type: "textarea",
    validation_type: false,
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxMemo",
    default_name: "Comentarios y requisitos",
    placeholder_id: "paxForm.paxMemoPh",
    default_placeholder: "Ej: Necesito 1 cuna en la habitación",
    required: false,
    field_name: "paxmemo",
    hidden: false,
    default_value: null
  },
  {
    type: "select",
    autocomplete: "",
    review_only: false,
    pax_type: "holder",
    name_id: "paxForm.paxTripReason",
    default_name: "Estado Civil",
    placeholder_id: "paxForm.paxTripReasonPh",
    default_placeholder: "Seleccione el motivo de su viaje",
    required: false,
    field_name: "tripreason",
    hidden: false,
    default_value: null,
    select_values: [
      {
        id: 6,
        field_id: 21,
        label: "Turismo",
        label_id: "paxForm.tripReasons.pleasure",
        value: 1,
        order: 1,
        created_at: "2023-12-19 15:25:27",
        updated_at: null
      },
      {
        id: 7,
        field_id: 21,
        label: "Negocios",
        label_id: "paxForm.tripReasons.work",
        value: 2,
        order: 2,
        created_at: "2023-12-19 15:25:55",
        updated_at: null
      },
      {
        id: 8,
        field_id: 21,
        label: "Otros",
        label_id: "paxForm.tripReasons.other",
        value: 3,
        order: 3,
        created_at: "2023-12-19 15:26:09",
        updated_at: null
      }
    ]
  },
  {
    id: 22,
    field_name: "triporigin",
    type: "country",
    pax_type: "all",
    required: "true",
    autocomplete: null,
    review_only: "false",
    name_id: "paxForm.paxTriporigin",
    default_name: "Origen d",
    placeholder_id: "paxForm.paxTriporigin",
    default_placeholder: "Seleccione el motivo de su viaje",
    hidden: "false",
    default_value: null,
    order: 22,
    created_at: "2023-12-19 15:23:59",
    updated_at: null
  },
  {
    id: 23,
    field_name: "tripdestinationcolombia",
    type: "desetination",
    pax_type: "all",
    required: "true",
    autocomplete: null,
    review_only: "false",
    name_id: "paxForm.paxTripdestination",
    default_name: "Origen d",
    placeholder_id: "paxForm.paxTripdestination",
    default_placeholder: "Seleccione el destino de su viaje",
    hidden: "false",
    default_value: null,
    order: 22,
    created_at: "2023-12-19 15:23:59",
    updated_at: null
  },
  {
    id: 23,
    field_name: "tripdestination",
    type: "text",
    pax_type: "all",
    required: "true",
    autocomplete: null,
    review_only: "false",
    name_id: "paxForm.paxTripdestination",
    default_name: "Origen d",
    placeholder_id: "paxForm.paxTripdestination",
    default_placeholder: "Seleccione el destino de su viaje",
    hidden: "false",
    default_value: null,
    order: 22,
    created_at: "2023-12-19 15:23:59",
    updated_at: null
  }
];

export default demoInputs;
